@import './styles/index.scss';
@import url('https://fonts.googleapis.com/css?family=Roboto:wght@400;700|Frank+Ruhl+Libre');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

* {
  box-sizing: border-box;
}

div::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 85%;
}

body,
a,
input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0.02em;
  color: #000;
}

a {
  text-decoration: none;
}

.clearfix {
  position: relative;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

// Table
.table {
  display: table;
}

.tbody,
.table-row-group {
  display: table-row-group;
}

.thead,
.table-header-group {
  display: table-header-group;
}

.trow,
.table-row {
  display: table-row;
}

.tcell,
.table-cell {
  display: table-cell !important;
}

*[data-conditional-opacity="1"] {
  transition: opacity 0.5s;
}

div[data-conditional-opacity-parent="1"]:not(:hover) *[data-conditional-opacity="1"] {
  opacity: 0 !important;
  pointer-events: none;
}

.add-menu-item {
  border-radius: 5px;

  &:hover {
    cursor: move;
    background-color: #dedede;
  }
}

.add-menu-thumbnail {
  transition: width 0.5s, height 0.5s;

  // &:hover {
  // width: 120px !important;
  // height: 120px !important;
  // }
}

::placeholder {
  font-size: 0.9em;
  letter-spacing: 1px;
  font-weight: normal;
  opacity: 0.8;
  text-transform: uppercase;
}

@keyframes indeterminate-progress-bar {
  from {
      left: -50%;
  }
  to {
      left: 100%;
  }
}